import type BaseGridControl from 'o365.controls.Grid.BaseGrid.ts';
import type { Location } from 'o365.modules.SelectionControl.ts';
import SelectionControl from 'o365.modules.SelectionControl.ts';
import BaseGridExtension from 'o365.controls.Grid.BaseExtension.ts';


declare module 'o365.controls.Grid.BaseGrid.ts' {
    interface BaseGridControl {
        navigation: BaseGridNavigation;
    }
}

export default class BaseGridNavigation<T extends BaseGridControl = BaseGridControl> extends BaseGridExtension<T> {
    static __extension = 'navigation';

    /** Reactive value to watch for edit mode availability */
    allowEditWatchTarget: () => boolean;

    get allowEdit() { return this.allowEditWatchTarget(); }
    selectionControl: SelectionControl;

    /** Check if cell location could exist */
    isPotentialCell(cell: Location) {
        if (cell.y < 0) {
            return false;
        }

        const control = this._getGridControl();
        const column = control.columns.columns[cell.x];

        return !!column;
    }

    /** Validate the row index for navigation */
    validRowIndex(rowIndex: number) {
        // ORIGINAL:
        //gridControl.value.dataObject.storage.data[cell.y]
        //cell.y < 0 || cell.y >= gridControl.value.dataObject.data.length
        return true;
    }

    /** Validate the row index for navigation in edit mode */
    validEditRowIndex(rowIndex: number) {
        // const row = gridControl.value.dataObject.storage.data[cell.y];
        // if (!row || row.o_groupHeaderRow) { return; }
        return true;
    }

    validEditColIndex(colIndex: number) {
        const gridControl = this._getGridControl();
        const column = gridControl.columns.columns[colIndex];
        if (!column || !column.editable || column.hide) { return false; }
        return true;
    }

    save() {
        // if (!gridControl.value.dataObject.disableSaveOncurrentIndexChange) {
        //     gridControl.value.save();
        // }
    }
    async copy(_e: KeyboardEvent) {
        //   gridControl.value.dataObject.selectionControl.copySelection(false, gridControl.value.dataColumns.columns);
        return false;
    }

    async paste(_e: KeyboardEvent) {
        //gridControl.value.dataObject.selectionControl.pasteSelection(e, gridControl.value.dataColumns.columns).then(pasted => {})
        return false;
    }

    handleEsc(activeCell: any) {
        // let currentItem: any;
        // if (activeCell) {
        //     currentItem = gridControl.value.dataObject.storage.data[activeCell.y];
        // }

        // if (currentItem?.hasChanges) {
        //     gridControl.value.dataObject.cancelChanges(currentItem.index);
        // }
    }

    handleCopyFromAbove(_cell, _activeCell) {
        // const rowAbove = gridControl.value.dataObject.data[cell.y];
        // const currentRow = gridControl.value.dataObject.data[activeCell.y];
        // const field = gridControl.value.dataColumns.columns[cell.x].field
        // if (field && currentRow && rowAbove) {
        //     if (gridFocusControl.editMode) {
        //         currentRow[field] += rowAbove[field];
        //     } else {
        //         currentRow[field] = rowAbove[field];
        //         gridFocusControl.enterEditMode();
        //     }
        // }
    }

    findClosestValidRowForInEditTab(row: number, increment: number, forceEditMode: boolean) {
        return row;
        // // let item = gridControl.value.dataObject.storage.data[row];
        // // if (options.forceEditMode && !item?.isBatchRecord) { item = null; row = null }
        // while (item && item['o_groupHeaderRow']) {
        //     row += increment;
        //     item = gridControl.value.dataObject.storage.data[row];
        // }
    }

    /** */
    setCurrentIndex(rowIndex: number) {
        const control = this._getGridControl();
        control.setCurrentIndex(rowIndex);
    }

    getSelectionClass(colIndex: number, rowIndex: number) {
        const control = this._getGridControl();
        if (!control.gridSelectionInterface?.selectionClassMap) { return; }

        const classMap = control.gridSelectionInterface?.selectionClassMap[rowIndex]?.[colIndex];
        let className: string;
        if (classMap) {
            className = classMap.join(' ');
        }
        const activeCell = control.gridFocusControl?.activeCell;
        if (activeCell && activeCell === `${colIndex}_${rowIndex}`) {
            className = (className ? className + ' ' : '') + 'o365-focus-cell';
        }

        return className;
    }

    initializeExtension() {
        this.selectionControl = new SelectionControl();
        this.allowEditWatchTarget = () => true;
    }
}